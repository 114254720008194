import axiosInstance from './axiosInstance';
import { IBanners } from '../models/IBanners';
import { PATH_NAME } from '../configs/pathName';
import { handleError } from '../helpers/Fetchers/fetchers';

export const getBannersAPI = async (id: string): Promise<IBanners> => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_BANNERS, {
    });
    if (response.data.success) {
      return response.data.result.items;
    } else {
      throw new Error('Failed to fetch banners');
    }
  } catch (error) {
    handleError(error,`Error fetching banners with ${id}:`);
    throw error;
  }
};

export const getBannersByIdAPI = async (id: string): Promise<IBanners> => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_BANNERS_BY_ID(id), {
    });
    if (response.data.success) {
      return response.data.result;
    } else {
      throw new Error('Failed to fetch banners');
    }
  } catch (error) {
    handleError(error,`Error fetching banners with ${id}:`);
    throw error;
  }
};

export const updateBannersAPI = async (id: string, banner: IBanners): Promise<IBanners> => {
  try {
    const response = await axiosInstance.put(PATH_NAME.API_BANNERS_BY_ID(id), banner);
    return response.data;
  } catch (error) {
    handleError(error,'Error updating banners:');
    throw error;
  }
};

export const createBannersAPI = async (bannerData: FormData): Promise<void> => {
    try {
      const response = await axiosInstance.post(PATH_NAME.API_BANNERS, bannerData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (!response.data.success) {
        throw new Error('Failed to create banners');
      }
    } catch (error) {
      handleError(error, 'Error creating banners:');
      throw error;
    }
  };  

export const deleteBannersAPI = async (id: number) => {
  try {
    await axiosInstance.delete(PATH_NAME.API_BANNERS_BY_ID(id.toString()));
  } catch (error) {
    handleError(error, 'deleting banners');
    throw new Error('Failed to delete banners');
  }
};