import useSWR from "swr";
import { getPaymentsByOrderId } from "../../../../api/PaymentAPI";
import { IPaymentOrder } from "../../../../models/IPayments";
import { Box, Container, Divider, Typography } from "@mui/material";
import { orderDetailStyles } from "../../../styles/PaymentList";

const PaymentRawData = ({ data }: { data: { [key: string]: any } }) => {
  if (!data) {
    return <Typography>No payment details available</Typography>;
  }

  return (
    <Box>
      {Object.entries(data).map(([key, value]) => (
        <Box key={key} sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
          <Typography sx={{ fontWeight: "bold" }}>{key}:</Typography>
          <Typography>{String(value)}</Typography>
        </Box>
      ))}
    </Box>
  );
};

const PaymentsList = ({ orderId }: { orderId: string }) => {
  const { data: payments, error } = useSWR<IPaymentOrder[]>(
    ["getPaymentsByOrderId", orderId],
    () => getPaymentsByOrderId(Number(orderId))
  );

const formatDate = (dateString: string | undefined) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return `${date.toLocaleDateString('en-SG', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })}`;
};

  return (
    <Container sx={orderDetailStyles.container}>
      <Typography variant="h6" sx={orderDetailStyles.title}>
        Payment Details List
      </Typography>

      {error && (
        <Typography color="error">
          Error: {error.message || "Failed to fetch payments!"}
        </Typography>
      )}
      {!payments && <Typography>Loading payments...</Typography>}
      {payments?.length === 0 && <Typography>No payments found.</Typography>}

      {/* Payments List */}
      {payments?.map((payment) => (
        <Box key={payment.id} sx={orderDetailStyles.summaryBox}>
          <Box sx={orderDetailStyles.headerRow}>
            <Typography sx={orderDetailStyles.headerText}>Invoice No</Typography>
            <Typography sx={orderDetailStyles.headerText}>{payment.invoice_no}</Typography>
          </Box>
          <Divider sx={orderDetailStyles.divider} />
          <Box sx={orderDetailStyles.headerRow}>
            <Typography sx={orderDetailStyles.headerText}>Created Date</Typography>
            <Typography sx={orderDetailStyles.headerText}>{formatDate(payment.created_at)}</Typography>
            </Box>
          <Divider sx={orderDetailStyles.divider} />
          <Box sx={orderDetailStyles.subtotalRow}>
            <Typography sx={orderDetailStyles.headerText}>Type</Typography>
            <Typography sx={orderDetailStyles.headerText}>{payment.type}</Typography>
          </Box>
          <Divider sx={orderDetailStyles.divider} />
          <Box sx={orderDetailStyles.subtotalRow}>
            <Typography sx={orderDetailStyles.headerText}>Status</Typography>
            <Typography>{payment.success ? "Success" : "Failed"}</Typography>
          </Box>
          <Divider sx={orderDetailStyles.divider} />
          <Box sx={orderDetailStyles.paymentBreakdownBox}>
            <Typography sx={orderDetailStyles.headerText}>Raw Data</Typography>
            <Divider sx={orderDetailStyles.divider} />
            <PaymentRawData data={payment.raw_data || {}} />
          </Box>
          <Divider sx={orderDetailStyles.divider} />
          <Box sx={orderDetailStyles.totalRow}>
            <Typography sx={orderDetailStyles.headerText}>Amount</Typography>
            <Typography>{formatCurrency(payment.amount)}</Typography>
          </Box>
        </Box>
      ))}
    </Container>
  );
};

const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

export default PaymentsList;
