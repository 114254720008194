export const orderDetailStyles = {
    container: { py: 4 ,},
    title: { mb: 2, fontWeight: "bold" },
    inputSection: { display: "flex", gap: 2, mb: 4 },
    summaryBox: { p: 2, border: "1px solid #ccc", borderRadius: "8px", mb: 2, backgroundColor:"#fff"  },
    headerRow: { display: "flex", justifyContent: "space-between", mb: 1 },
    subtotalRow: { display: "flex", justifyContent: "space-between", mt: 1 },
    paymentBreakdownBox: { mt: 2 },
    totalRow: { display: "flex", justifyContent: "space-between", mt: 2 },
    headerText: { fontSize: "16px", fontWeight: "500" },
    divider: { my: 1 },
  };