import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ISkuDetails } from '../../../../models/ISku';
import { getSkuDetailsBySku } from '../../../../api/GetAllSkuAPI';
import { Typography, Box, CircularProgress, Container, Divider, Button } from '@mui/material';

const SkuDetails: React.FC = () => {
  const { sku_sku } = useParams<{ sku_sku: string }>();
  const [skuDetails, setSkuDetails] = useState<ISkuDetails | null>(null);
  const [, setEditedSku] = useState<ISkuDetails | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (sku_sku) {
      const fetchSkuDetails = async () => {
        try {
          const details = await getSkuDetailsBySku(sku_sku);
          setSkuDetails(details);
          setEditedSku(details);
        } catch (error) {
          console.error('Error fetching SKU details:', error);
        }
      };

      fetchSkuDetails();
    }
  }, [sku_sku]);

  const handleEdit = () => {
    if (sku_sku) {
      navigate(`/product/edit_skus/${sku_sku}`);
    }
  };

  if (!skuDetails) {
    return <CircularProgress />;
  }

  return (
    <Container sx={{ maxWidth: '500px', padding: 3, backgroundColor: '#ffffff', borderRadius: 2 }}>
      <Typography variant="h6">
         Sku Details
        </Typography>
    {skuDetails.image_url && (
        <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
          <img src={skuDetails.image_url} alt={skuDetails.sku_sku} style={{ maxWidth: '100%', borderRadius: 8 }} />
        </Box>
      )}
    <Divider sx={{ width: '100%', marginBottom: 3 }} />
    
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography component="span" variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}>
         SKU:
        </Typography>
        <Typography component="span" variant="body1" sx={{ flex: 1 }}>
        {skuDetails.sku_sku}
        </Typography>
      </Box>
      <Divider sx={{ marginY: 1 }} />
    </Box>

    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography component="span" variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}>
         Name:
        </Typography>
        <Typography component="span" variant="body1" sx={{ flex: 1 }}>
        {skuDetails.name}
        </Typography>
      </Box>
      <Divider sx={{ marginY: 1 }} />
    </Box>

    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography component="span" variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}>
          Price:
        </Typography>
        <Typography component="span" variant="body1" sx={{ flex: 1 }}>
          {(skuDetails.price)}
        </Typography>
      </Box>
      <Divider sx={{ marginY: 1 }} />
    </Box>

    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography component="span" variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}>
          Weight:
        </Typography>
        <Typography component="span" variant="body1" sx={{ flex: 1 }}>
          {skuDetails.weight || 'N/A'}
        </Typography>
      </Box>
      <Divider sx={{ marginY: 1 }} />
    </Box>

    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography component="span" variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}>
          Size:
        </Typography>
        <Typography component="span" variant="body1" sx={{ flex: 1 }}>
          {skuDetails.size || 'N/A'}
        </Typography>
      </Box>
      <Divider sx={{ marginY: 1 }} />
    </Box>

    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography component="span" variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}>
          Sales Ranking:
        </Typography>
        <Typography component="span" variant="body1" sx={{ flex: 1 }}>
          {skuDetails.sales_ranking}
        </Typography>
      </Box>
      <Divider sx={{ marginY: 1 }} />
    </Box>

    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography component="span" variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}>
          Average Rating:
        </Typography>
        <Typography component="span" variant="body1" sx={{ flex: 1 }}>
          {skuDetails.average_rating || 0} ⭐
        </Typography>
      </Box>
      <Divider sx={{ marginY: 1 }} />
    </Box>

    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography component="span" variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}>
          Review Count:
        </Typography>
        <Typography component="span" variant="body1" sx={{ flex: 1 }}>
          {skuDetails.review_count}
        </Typography>
      </Box>
      <Divider sx={{ marginY: 1 }} />
    </Box>

    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography component="span" variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}>
          Description:
        </Typography>
        <Typography component="span" variant="body1" sx={{ flex: 1 }}>
          {skuDetails.description || 'N/A'}
        </Typography>
      </Box>
      <Divider sx={{ marginY: 1 }} />
    </Box>

    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
      <Button variant="contained" color="primary" sx={{ marginRight: 1 }} onClick={handleEdit}>
          Edit SKU
        </Button>
      <Button variant="contained" color="primary">
        Delete SKU
      </Button>
    </Box>
  </Container>
);
};

export default SkuDetails;