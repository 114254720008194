import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack'; // For notifications if you're using notistack
import { createBannersAPI } from '../../../api/Banners';

const AddBanner: React.FC = () => {
  const [name, setName] = useState('');
  const [placement, setPlacement] = useState('');
  const [active, setActive] = useState(false);
  const [bannerImages, setBannerImages] = useState<(File | null)[]>([null, null, null, null, null]);
  const [imagePreviews, setImagePreviews] = useState<(string | null)[]>([null, null, null, null, null]);

  const handleImageChange = (index: number, file: File | null) => {
    const updatedImages = [...bannerImages];
    const updatedPreviews = [...imagePreviews];

    if (file) {
      updatedImages[index] = file;
      updatedPreviews[index] = URL.createObjectURL(file);
    } else {
      updatedImages[index] = null;
      updatedPreviews[index] = null;
    }

    setBannerImages(updatedImages);
    setImagePreviews(updatedPreviews);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('placement', placement);
      formData.append('active', active.toString());

      const urls: string[] = [];
      bannerImages.forEach((image, index) => {
        if (image) {
          formData.append(`banner_image_${index + 1}`, image);
          urls.push(image.name); // Add the file name to the URLs array
        }
      });

      formData.append('urls', JSON.stringify(urls)); // Append the URLs array as JSON

      await createBannersAPI(formData);
      enqueueSnackbar('Banner created successfully!', { variant: 'success' });

      // Reset form
      setName('');
      setPlacement('');
      setActive(false);
      setBannerImages([null, null, null, null, null]);
      setImagePreviews([null, null, null, null, null]);
    } catch (error) {
      enqueueSnackbar('Failed to create banner. Please try again.', { variant: 'error' });
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Create</Typography>
      
      <Box display="flex" flexDirection="column" gap={2}>
        <TextField
          label="Name"
          fullWidth
          size="small"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="Placement"
          type="text"
          fullWidth
          size="small"
          value={placement}
          onChange={(e) => setPlacement(e.target.value)}
        />
        {/* <FormControlLabel
          control={<Switch color="primary" checked={active} onChange={(e) => setActive(e.target.checked)} />}
          label="Active"
          labelPlacement="end"
        /> */}
      </Box>

      <Typography variant="subtitle1" mt={3}>
        Home Banner Images
      </Typography>
      <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
        {bannerImages.map((_, index) => (
          <Box key={index} display="flex" flexDirection="column" alignItems="center" gap={1}>
            <Button
              variant="outlined"
              component="label"
              style={{ height: '50px', textTransform: 'none' }}
            >
              {bannerImages[index] ? 'Change Image' : `Upload Banner ${index + 1}`}
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => handleImageChange(index, e.target.files?.[0] || null)}
              />
            </Button>
            {imagePreviews[index] && (
              <Box mt={1}>
                <img
                  src={imagePreviews[index]!}
                  alt={`Preview ${index + 1}`}
                  style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                />
              </Box>
            )}
          </Box>
        ))}
      </Box>

      <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
        <Button variant="outlined">
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default AddBanner;