import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, Divider, List, ListItem, ListItemText, Switch, FormControlLabel, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Banners: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();

  // Mock Data for banners (grouped)
  const [banners, setBanners] = useState([
    { id: 1, name: 'Home Banner', active: true },
    { id: 2, name: '12.12 Sales Banner', active: false },
  ]);

  // Handle tab change
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  // Handle active/inactive toggle
  const toggleBannerStatus = (bannerId: number) => {
    setBanners((prevBanners) =>
      prevBanners.map((banner) =>
        banner.id === bannerId ? { ...banner, active: !banner.active } : banner
      )
    );
  };

  // Add a new banner
  const handleCreateBanner = () => {
    navigate('/Content/Banners/AddBanners');
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" gutterBottom>
          Banners
        </Typography>
        <Button variant="contained" color="primary" onClick={handleCreateBanner}>
          Add
        </Button>
      </Box>
      <Divider sx={{ my: 2 }} />

      {/* Tabs for Active and Inactive */}
      <Tabs value={tabIndex} onChange={handleTabChange} textColor="primary" indicatorColor="primary">
        <Tab label="Active" />
        <Tab label="Inactive" />
      </Tabs>
      <Divider sx={{ my: 2 }} />

      {/* Render Active or Inactive Banners */}
      {tabIndex === 0 && (
        <Box>
          <List>
            {banners
              .filter((banner) => banner.active)
              .map((banner) => (
                <ListItem key={banner.id} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <ListItemText primary={banner.name} secondary={`ID: ${banner.id}`} />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={banner.active}
                        color="primary"
                        onChange={() => toggleBannerStatus(banner.id)}
                      />
                    }
                    label="Active"
                  />
                </ListItem>
              ))}
            {banners.filter((banner) => banner.active).length === 0 && (
              <Typography variant="body1" color="text.secondary">
                No active banners.
              </Typography>
            )}
          </List>
        </Box>
      )}

      {tabIndex === 1 && (
        <Box>
          <List>
            {banners
              .filter((banner) => !banner.active)
              .map((banner) => (
                <ListItem key={banner.id} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <ListItemText primary={banner.name} secondary={`ID: ${banner.id}`} />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={banner.active}
                        color="primary"
                        onChange={() => toggleBannerStatus(banner.id)}
                      />
                    }
                    label="Active"
                  />
                </ListItem>
              ))}
            {banners.filter((banner) => !banner.active).length === 0 && (
              <Typography variant="body1" color="text.secondary">
                No inactive banners.
              </Typography>
            )}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default Banners;
