import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

const AddCollection: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    banner_url: '',
    priority: '0',
    tagCode: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      console.log(formData); // Replace with API call
      enqueueSnackbar('Collection created successfully!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to create collection.', { variant: 'error' });
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h5" gutterBottom>Create Collection</Typography>
      
      <Box display="flex" flexDirection="column" gap={2}>
        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          size="small"
          fullWidth
        />
        <TextField
          label="Banner URL"
          name="banner_url"
          value={formData.banner_url}
          onChange={handleChange}
          size="small"
          fullWidth
        />
        <TextField
          label="Priority"
          name="priority"
          type="number"
          value={formData.priority}
          onChange={handleChange}
          size="small"
          fullWidth
        />
        <Typography variant="subtitle1" mt={3}>Tags</Typography>
      <Box display="flex" gap={1} alignItems="center" mt={1}>
        <TextField
          label="Tag Code"
          name="tagCode"
          value={formData.tagCode}
          onChange={handleChange}
          size="small"
        />
        <Button variant="outlined" size="small">+</Button>
      </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
        <Button variant="outlined" color="primary">
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default AddCollection;
