import React, { useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Box,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { createSkuDetails } from '../../../../api/GetAllSkuAPI';
import { ISkuDetails } from '../../../../models/ISku';

const AddSkuForm: React.FC = () => {
  const [skuDetails, setSkuDetails] = useState<ISkuDetails>({
    short_name: '',
    name: '',
    sku_sku: '',
    sales_ranking: 0,
    collections: [],
    batches: [],
    price: 0,
    description: '',
    image_url: '',
    additional_image_urls: [],
    weight: '',
    size: '',
    average_rating: 0,
    review_count: 0,
    id: 0,
    is_deleted: false,
    created_at: '',
    updated_at: '',
    deleted_at: null,
    created_by: 0,
    updated_by: 0,
    deleted_by: null,
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setSkuDetails((prevDetails) => ({
      ...prevDetails,
      [name]: name === 'price' || name === 'sales_ranking' || name === 'average_rating' || name === 'review_count'
        ? parseFloat(value)
        : value,
    }));
  };

  const handleCollectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const collectionIds = e.target.value.split(',').map((id) => parseInt(id.trim(), 10));
    setSkuDetails((prevDetails) => ({
      ...prevDetails,
      collections: collectionIds,
    }));
  };

  const handleSubmit = async () => {
    try {
      const newSku = await createSkuDetails(skuDetails.sku_sku, skuDetails);
      enqueueSnackbar(`SKU ${newSku.name} created successfully!`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to create SKU', { variant: 'error' });
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Add New SKU
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Short Name"
            variant="outlined"
            fullWidth
            name="short_name"
            value={skuDetails.short_name}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            name="name"
            value={skuDetails.name}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="SKU"
            variant="outlined"
            fullWidth
            name="sku_sku"
            value={skuDetails.sku_sku}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Sales Ranking"
            variant="outlined"
            fullWidth
            name="sales_ranking"
            type="number"
            value={skuDetails.sales_ranking}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Price"
            variant="outlined"
            fullWidth
            name="price"
            type="number"
            value={skuDetails.price}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Weight"
            variant="outlined"
            fullWidth
            name="weight"
            value={skuDetails.weight}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Size"
            variant="outlined"
            fullWidth
            name="size"
            value={skuDetails.size}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            name="description"
            multiline
            rows={4}
            value={skuDetails.description}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Collections (comma-separated IDs)"
            variant="outlined"
            fullWidth
            name="collections"
            value={skuDetails.collections.join(', ')}
            onChange={handleCollectionChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12} >
          <TextField
            label="Batches"
            variant="outlined"
            fullWidth
            name="batches"
            value={skuDetails.batches}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Image URL"
            variant="outlined"
            fullWidth
            name="image_url"
            value={skuDetails.image_url}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Additional Image URLs (comma-separated)"
            variant="outlined"
            fullWidth
            name="additional_image_urls"
            size="small"
            value={skuDetails.additional_image_urls.join(', ')}
            onChange={(e) =>
              setSkuDetails((prevDetails) => ({
                ...prevDetails,
                additional_image_urls: e.target.value.split(',').map((url) => url.trim()),
              }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Average Rating"
            variant="outlined"
            fullWidth
            name="average_rating"
            type="number"
            value={skuDetails.average_rating}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Review Count"
            variant="outlined"
            fullWidth
            name="review_count"
            type="number"
            value={skuDetails.review_count}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="small"
            onClick={handleSubmit}
          >
            Create SKU
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddSkuForm;
