import React from "react";
import { Container, Typography, Box, Table, TableBody, TableCell, TableHead, TableRow, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { getAllSkus } from "../../../../api/GetAllSkuAPI";
import { ISku } from "../../../../models/ISku";

const SkuListPage = () => {
  const { data: skus, error } = useSWR("getAllSkus", getAllSkus);
  const navigate = useNavigate();

  if (error) {
    return (
      <Container>
        <Typography color="error" variant="h6">
          Failed to load SKUs.
        </Typography>
      </Container>
    );
  }

  if (!skus) {
    return (
      <Container>
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h6" gutterBottom>
        SKU List
      </Typography>
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>SKU</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {skus.map((sku: ISku) => (
              <TableRow key={sku.id}>
                <TableCell>{sku.id}</TableCell>
                <TableCell>{sku.name}</TableCell>
                <TableCell>{sku.sku}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`${sku.sku}`)}
                  >
                    View Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Container>
  );
};

export default SkuListPage;
