import React, { useState } from 'react';
import DataTable from '../../../common/DataTable/DataTable';
import { Box, Button, IconButton, TextField } from '@mui/material';
import useSWR from 'swr';
import { PATH_NAME } from '../../../../configs/pathName';
import { fetchOrdersAPI, searchOrderByRef } from '../../../../api/OrderAPI';
import { IOrder, IPayment } from '../../../../models/IOrders';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs from 'dayjs';

const OrderTable: React.FC = () => {
  const navigate = useNavigate();

  const today = dayjs().format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState<string>(today);
  const [endDate, setEndDate] = useState<string>(today);
  const [orderRef, setOrderRef] = useState<string>('');
  const [searchResults, setSearchResults] = useState<IOrder[] | null>(null);

  const { data: ordersData, error, isLoading } = useSWR(
    {
      path: PATH_NAME.API_ADMIN_ORDERS_HISTORY,
      limit: 1000,
      offset: 0,
      start_date: startDate || undefined,
      end_date: endDate || undefined,
    },
    ({ limit, offset, start_date, end_date }) =>
      fetchOrdersAPI(limit, offset, start_date, end_date)
  );

  const orders = searchResults || (ordersData?.result?.items.orders || []) as IOrder[];

  const columns = [
    { field: 'user_id', headerName: 'User ID', flex: 1 },
    { field: 'id', headerName: 'Order ID', flex: 1 },
    { field: 'subtotal', headerName: 'Subtotal', flex: 1 },
    {
      field: 'type',
      headerName: 'Payment Methods',
      flex: 1,
      renderCell: (params: any) => {
        if (Array.isArray(params.row.payments) && params.row.payments.length > 0) {
          return (
            <span>
              {params.row.payments.map((payment: IPayment, index: number) => (
                <span key={index}>
                  {payment.type}{index < params.row.payments.length - 1 ? ', ' : ''}
                </span>
              ))}
            </span>
          );
        }
        return <span>No payments</span>;
      },
    },
    { field: 'payment_amount', headerName: 'Payment Amount', flex: 1 },
    {
      field: 'created_at', headerName: 'Created At', flex: 2,
      renderCell: (params: any) => dayjs(params.row.created_at).format('DD-MM-YYYY HH:mm'),
    },
    { field: 'order_ref', headerName: 'Order Ref', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: any) => (
        <div>
          <IconButton
            aria-label="view"
            size="small"
            color="primary"
            onClick={() => handleViewClick(params.row.id)}
          >
            <VisibilityIcon fontSize="small" />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleViewClick = (id: number) => {
    navigate(`/order_details/${id}`);
  };

  const handleSearch = async () => {
    if (!orderRef.trim()) return;
    try {
      const response = await searchOrderByRef(orderRef);
      if (response.success && response.result) {
        setSearchResults(response.result.items);
      } else {
        setSearchResults([]);
      }
    } catch (err) {
      console.error('Error searching order by ref:', err);
      setSearchResults([]);
    }
  };

  const handleClear = () => {
    setOrderRef('');
    setSearchResults(null);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading orders</div>;

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, gap: 1 }}>
        <TextField
          label="Order Ref"
          value={orderRef}
          size="small"
          onChange={(e) => setOrderRef(e.target.value)}
          variant="outlined"
        />
        <Button variant="contained" color="primary" onClick={handleSearch}>
          Search
        </Button>
        <Button variant="outlined" color="primary" onClick={handleClear}>
          Clear
        </Button>
        <TextField
          label="Start Date"
          type="date"
          size="small"
          value={startDate || ''}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Date"
          type="date"
          size="small"
          value={endDate || ''}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <DataTable
        rows={orders}
        columns={columns}
        getRowId={(row) => row.id}
        loading={isLoading}
      />
    </div>
  );
};

export default OrderTable;