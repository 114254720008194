import axiosInstance from '../api/axiosInstance';
import { PATH_NAME } from '../configs/pathName';
import { handleError } from '../helpers/Fetchers/fetchers';
import { IOrder } from '../models/IOrders';

export const fetchOrdersAPI = async (limit: number, offset: number, start_date?: string,
  end_date?: string): Promise<{ result: { items: { orders: IOrder[] } } }> => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_ORDERS_HISTORY, {
      params: { limit, offset, start_date, end_date },
    });
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching orders:');
    throw error;
  }
};

export const getOrderById = async (id: string): Promise<IOrder> => {
  const response = await axiosInstance.get(PATH_NAME.API_ADMIN_ORDERS(id));
  return response.data.result;
};

export const getOrderByUserId = async (id: string): Promise<IOrder> => {
  const response = await axiosInstance.get(PATH_NAME.API_ADMIN_ORDERS(id));
  return response.data.result.items;
};

export const getUserOrdersAPI = async (userId: number) => {
  const response = await axiosInstance.get(`/admin-api/orders/get_by_user_id?user_id=${userId}`);
  return response.data;
};

export const deductWalletAPI = async (payload: {
  customer_id: number;
  order_id: number;
  amount: number;
  description: string;
}) => {
  try {
    const response = await axiosInstance.post(PATH_NAME.API_ADMIN_ORDER_DEDUCT_WALLET,payload);
    
    return response.data;
  } catch (error) {
    handleError(error, 'deducting wallet amount');
    throw error;
  }
};

export const deductCreditCardAPI = async (payload: {
  customer_id: number;
  order_id: number;
  amount: number;
  description: string;
}) => {
  try {
    const response = await axiosInstance.post(PATH_NAME.API_ADMIN_ORDER_DEDUCT_CREDIT_CARD,payload);
    return response.data;
  } catch (error) {
    handleError(error, 'deducting credit card amount');
    throw error;
  }
};

export const searchOrderByRef = async (orderRef: string) => {
  const response = await axiosInstance.get(PATH_NAME.API_ADMIN_ORDER_SEARCH_BY_REF(orderRef));
  return response.data;
};