import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../../common/DataTable/DataTable';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Box, TextField, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import useSWR, { useSWRConfig } from 'swr';
import { PATH_NAME } from '../../../../configs/pathName';
import { deleteUserAPI, UserSearchAPI } from '../../../../api/UserAPI';
import { IUserFull } from '../../../../models/IUser';

const UserTable: React.FC = () => {
  const { mutate } = useSWRConfig();
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState({ email: '', mobile: '', name: '',id:'' });
  const [filters, setFilters] = useState({ email: '', mobile: '', name: '',id:'' });
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  const queryParams = useMemo(() => {
    const params = new URLSearchParams();
    if (filters.email) params.set('email', filters.email);
    if (filters.mobile) params.set('mobile', filters.mobile);
    if (filters.name) params.set('name', filters.name);
    if (filters.id) params.set('id', filters.id);
    return params.toString();
  }, [filters]);

  const { data: users = [], error, isLoading } = useSWR(
    `${PATH_NAME.API_ADMIN_USER_SEARCH}?${queryParams}`,
    UserSearchAPI
  );  

  const sortedUsers = useMemo(() => {
    return users.result?.items ? [...users.result.items].sort((a: IUserFull, b: IUserFull) => a.id - b.id) : [];
  }, [users]);

  const columns = [
    { field: 'id', headerName: 'ID', flex: 2 },
    { field: 'firstname', headerName: 'First Name', flex: 2 },
    { field: 'lastname', headerName: 'Last Name', flex: 2 },
    { field: 'full_mobile', headerName: 'Phone Number', flex: 2 },
    { field: 'email', headerName: 'Email', flex: 4 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: any) => (
        <div>
          <IconButton
            aria-label="edit"
            size="small"
            color="primary"
            onClick={() => handleEdit(params.row.id)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            color="primary"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleDeleteClick = (id: number) => {
    setSelectedUserId(id);
    setOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedUserId !== null) {
      try {
        await deleteUserAPI(selectedUserId);
        await mutate(`${PATH_NAME.API_ADMIN_USER_SEARCH}?${queryParams}`);
      } catch (error) {
        console.error('Failed to delete user:', error);
      }
    }
    setOpen(false);
    setSelectedUserId(null);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUserId(null);
  };

  const handleEdit = (id: string) => {
    navigate(`/users/user_details/${id}`);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearch = () => {
    setFilters(searchInput);
  };

  const handleClear = () => {
    setSearchInput({ email: '', mobile: '', name: '',id:'' });
    setFilters({ email: '', mobile: '', name: '',id:'' });
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading Users</div>;

  return (
    <div>
      <Typography variant="h6">Filter By</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 , mt:1}}>
        <Grid container spacing={1} sx={{ flexGrow: 1 }}>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Email"
              name="email"
              variant="outlined"
              size="small"
              fullWidth
              value={searchInput.email}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="Mobile"
              name="mobile"
              variant="outlined"
              size="small"
              fullWidth
              value={searchInput.mobile}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="Name"
              name="name"
              variant="outlined"
              size="small"
              fullWidth
              value={searchInput.name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="id"
              name="id"
              variant="outlined"
              size="small"
              fullWidth
              value={searchInput.id}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button variant="contained" color="primary" onClick={handleSearch}>
            Search
          </Button>
          <Button variant="outlined" color="primary" onClick={handleClear}>
            Clear
          </Button>
        </Box>
      </Box>

      <DataTable
        rows={sortedUsers}
        columns={columns}
        getRowId={(row) => row.id}
        loading={isLoading}
      />
      
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserTable;