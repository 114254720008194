import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const AddVideo: React.FC = () => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>Create</Typography>

      <Box display="flex" flexDirection="column" gap={2}>

      <Typography variant="subtitle1" mt={3}>HomePage Section 2 Video</Typography>
      <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
        <Button
          variant="outlined"
          fullWidth
          component="label"
          style={{ width: '40%', height: '100px', textTransform: 'none' }}
        >
          Upload Video
          <input
            type="file"
            accept="video/*"
            hidden
          />
        </Button>
      </Box>

      <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
        <Button variant="outlined">
          Cancel
        </Button>
        <Button variant="contained" color="primary">
          Submit
        </Button>
      </Box>
    </Box>
    </Box>
  );
};

export default AddVideo;
