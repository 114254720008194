import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ISkuDetails } from '../../../../models/ISku';
import { getSkuDetailsBySku, updateSkuDetails } from '../../../../api/GetAllSkuAPI';
import { Typography, Box, CircularProgress, Container, Divider, TextField, Button, Grid } from '@mui/material';
import { toast } from '../../../common/SnackBar/snackBar';

const EditSkuDetails: React.FC = () => {
  const { sku_sku } = useParams<{ sku_sku: string }>();
  const [skuDetails, setSkuDetails] = useState<ISkuDetails | null>(null);
  const [editedSkuDetails, setEditedSkuDetails] = useState<ISkuDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    if (sku_sku) {
      const fetchSkuDetails = async () => {
        try {
          setLoading(true);
          const details = await getSkuDetailsBySku(sku_sku);
          setSkuDetails(details);
          setEditedSkuDetails(details);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching SKU details:', error);
          setLoading(false);
        }
      };

      fetchSkuDetails();
    }
  }, [sku_sku]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown }>) => {
    const { name, value } = e.target;
    if (name && editedSkuDetails) {
      setEditedSkuDetails({
        ...editedSkuDetails,
        [name]: value,
      });
    }
  };

  const handleSave = async () => {
    if (sku_sku && editedSkuDetails) {
      try {
        setLoading(true);
        const updatedSku = await updateSkuDetails(sku_sku, editedSkuDetails);
        setSkuDetails(updatedSku);
        setEditedSkuDetails(updatedSku); // Keep the updated data in state
        setLoading(false);
        toast.success("SKU details updated successfully!"); // Show success message
        
        // Navigate back to SKU page after successful edit
        navigate(`/product/skus/${sku_sku}`); // Adjust the path as needed for your SKU page
      } catch (error) {
        console.error('Error updating SKU:', error);
        setLoading(false);
        toast.error("Failed to update SKU details.");
      }
    }
  };

  const handleCancel = async () => {
    navigate(`/product/skus/${sku_sku}`);
  }

  if (loading || !skuDetails) {
    return <CircularProgress />;
  }

  return (
    <Container sx={{ maxWidth: '800px', padding: 3, backgroundColor: '#ffffff', borderRadius: 2 }}>
      {skuDetails.image_url && (
        <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
          <img src={skuDetails.image_url} alt={skuDetails.sku_sku} style={{ maxWidth: '100%', borderRadius: 8 }} />
        </Box>
      )}
      <Divider sx={{ width: '100%', marginBottom: 3 }} />

      {/* SKU Edit Form */}
      <Box>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>Edit SKU Details</Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="SKU"
              name="sku_sku"
              value={editedSkuDetails?.sku_sku || ''}
              onChange={handleChange}
              fullWidth
              sx={{ marginBottom: 2 }}
              disabled
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Name"
              name="name"
              value={editedSkuDetails?.name || ''}
              onChange={handleChange}
              fullWidth
              sx={{ marginBottom: 2 }}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Price"
              name="price"
              type="number"
              value={editedSkuDetails?.price || ''}
              onChange={handleChange}
              fullWidth
              size="small"
              sx={{ marginBottom: 2 }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Weight"
              name="weight"
              value={editedSkuDetails?.weight || ''}
              onChange={handleChange}
              fullWidth
              size="small"
              sx={{ marginBottom: 2 }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Size"
              name="size"
              value={editedSkuDetails?.size || ''}
              onChange={handleChange}
              fullWidth
              size="small"
              sx={{ marginBottom: 2 }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Sales Ranking"
              name="sales_ranking"
              type="number"
              value={editedSkuDetails?.sales_ranking || ''}
              onChange={handleChange}
              fullWidth
              size="small"
              sx={{ marginBottom: 2 }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Average Rating"
              name="average_rating"
              type="number"
              value={editedSkuDetails?.average_rating || ''}
              onChange={handleChange}
              fullWidth
              size="small"
              sx={{ marginBottom: 2 }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Review Count"
              name="review_count"
              type="number"
              value={editedSkuDetails?.review_count || ''}
              onChange={handleChange}
              fullWidth
              size="small"
              sx={{ marginBottom: 2 }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Description"
              name="description"
              value={editedSkuDetails?.description || ''}
              onChange={handleChange}
              fullWidth
              size="small"
              sx={{ marginBottom: 2 }}
              multiline
              rows={4}
            />
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'right', marginTop: 2, gap:1 }}>
          <Button variant="outlined" color="primary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default EditSkuDetails;
