import { PATH_NAME } from '../configs/pathName';
import { ISku, ISkuAPIResponse, ISkuDetails } from '../models/ISku';
import axiosInstance from './axiosInstance';

export const getAllSkus = async (): Promise<ISku[]> => {
    const response = await axiosInstance.get<ISkuAPIResponse>(PATH_NAME.API_GET_ALL_SKU);
    return response.data.result.items;
  };

export const getSkuDetailsBySku = async (sku_sku: string): Promise<ISkuDetails> => {
    const response = await axiosInstance.get<{ result: ISkuDetails }>(PATH_NAME.API_GET_SKU_DETAILS(sku_sku));
  
    if (!response.data || !response.data.result) {
      throw new Error('Invalid response format');
    }
  
    return response.data.result;
  };
  
  export const updateSkuDetails = async (sku_sku: string, skuDetails: ISkuDetails): Promise<ISkuDetails> => {
    const response = await axiosInstance.put<{ result: ISkuDetails }>(
      PATH_NAME.API_ADMIN_SKU_DETAILS(sku_sku),
      skuDetails
    );
  
    if (!response.data || !response.data.result) {
      throw new Error('Invalid response format');
    }
  
    return response.data.result;
  };

export const createSkuDetails = async (sku_sku: string, skuDetails: ISkuDetails): Promise<ISkuDetails> => {
    const response = await axiosInstance.post<{ result: ISkuDetails }>(
      PATH_NAME.API_ADMIN_SKU_DETAILS(sku_sku),
      skuDetails
    );
  
    if (!response.data || !response.data.result) {
      throw new Error('Invalid response format');
    }
  
    return response.data.result;
  };

